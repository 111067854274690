import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('video_story', (id, shareableSlug, enableCaption) => ({
  id: id,
  shareableSlug: shareableSlug,
  enableCaption: enableCaption,
  player: null,
  muted: true,
  isSharing: false,
  expandText: false,
  init() {
    if (window.vs_player_muted == undefined) {
      window.vs_player_muted = true;
    }
    if (!window.vs_players) window.vs_players = [];
    if (!window.vs_players[this.id]) {
      window.vs_players[this.id] = this.jwp_init_call_back.bind(this);
    } else {
      this.jwp_init_call_back();
    }
  },
  jwp_init_call_back () {
    this.player = window.vs_players[this.id];
    this.muted = window.vs_player_muted;
    if (this.player.muted && !this.muted) this.unmute();
    if (!this.player.muted && this.muted) this.mute();
    this.player.addEventListener('volumechange', (e) => {
      this.muted = e.target.muted;
      window.vs_player_muted = e.target.muted;
    });
  },
  play () {
    if (!this.player) return;
    this.player.currentTime = 0;
    if (!window.vs_player_muted) {
      this.unmute();
    }
    this.player.play();
  },
  pause () {
    if (!this.player) return;
    this.player.pause();
  },
  mute () {
    if (!this.player) return;
    this.player.muted = true;
  },
  unmute () {
    if (!this.player) return;
    this.player.muted = false;
  },
  shareStory() {
    if (this.isSharing) return;
    this.isSharing = true;
    
    let fullUrl = "https://www.tvsyd.dk" + this.shareableSlug;
    
    if (navigator.share !== undefined) {
      navigator.share({
        title: document.title,
        text: '',
        url: fullUrl
      })
      .then(() => {
        console.log('Shared!');
        this.isSharing = false;
      })
      .catch(err => {
        console.error(err);
        this.isSharing = false;
      });
    } else {
      window.location = `mailto:?subject=${document.title}&body=Se den her h%C3%B8jkantsvideo fra tvSyd%0A${fullUrl}`;
      this.isSharing = false;
    }
  },
  toggleText() {
    this.expandText = !this.expandText;
  },
  trackContentLink(content_url) {
    plausible('content_link_clicked', {
      props: {
        content_url: content_url, video_url: this.shareableSlug
      }
    });
  }
}));

Alpine.data('video_story_player', (id) => ({
  id: id,
  player: null,
  init () {
    this.player = this.$el;
    this.registerPlayer();
  },
  registerPlayer () {
    if (window.vs_players === undefined) window.vs_players = {};
    if (window.vs_players_pause === undefined) window.vs_players_pause = {};
    const cb = window.vs_players[this.id];
    window.vs_players[this.id] = this.player;
    if (typeof cb === 'function') cb();
  },
}));

Alpine.data('video_story_scroll_top', () => ({
  init() {
    setTimeout(() => {
      this.$el.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }, 100)
  },
}));