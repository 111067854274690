// This is all you.

//import Alpine from 'alpinejs';
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

import "../../public/vendor/statamic/frontend/js/helpers.js";
import Precognition from 'laravel-precognition-alpine';

// import and register Swiper custom elements
import { register } from 'swiper/element/bundle';
register();

import "./alpine/AppBanner.js";
import './alpine/AppRequestTemp.js';
import './alpine/BreakingNewsComponent';
import './alpine/Carousel.js';
import './alpine/Chartbeat.js';
import "./alpine/CookieNotice.js";
import "./alpine/CookieOverview.js";
import './alpine/DatePicker.js';
import './alpine/DetailsButton.js';
import './alpine/Duration.js';
import './alpine/ExternalQuiz.js';
import './alpine/HorizSlider.js';
import './alpine/ImageComparison.js';
import './alpine/LargeVideo.js';
import './alpine/LatestNews.js';
import './alpine/Mosaik.js';
import './alpine/Newsletter.js';
import "./alpine/PlayMenuApp.js";
import './alpine/PriorityScroll.js';
import './alpine/PushMessageDialog.js';
import './alpine/Recipe.js';
import "./alpine/ReelsPlayer.js";
import './alpine/RelativeTime.js';
import "./alpine/ScrollText.js";
import './alpine/SimplePlayer.js';
import './alpine/StorkAnswers.js';
import './alpine/StorkForm.js';
import './alpine/StorkInfo.js';
import './alpine/SwiperHelper.js';
import './alpine/Tabs.js';
import './alpine/TeaserVideo.js';
import './alpine/Textbox.js';
import './alpine/Topmenu.js';
import './alpine/UrlUpdater.js';
import './alpine/VideoStory.js';
import './alpine/VideoPlayer.js';
import './alpine/VideoTable.js';
import './alpine/Webscreen.js';

import './syd/priority_scroll.js';

Alpine.plugin(Precognition);
Livewire.start();
