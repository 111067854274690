function priority_scroll() {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, 100)
};

let priorityScrollsTriggers = document.getElementsByClassName("priority-scroll-trigger");

for(let i = 0; i < priorityScrollsTriggers.length; i++) {
  if(window.navigator.userAgent.indexOf("median") === -1) {
    priority_scroll();
  }
}