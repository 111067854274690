import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('relative_time', (settingsObj) => ({
  formatTemplate: settingsObj.formatTemplate,
  datetime: settingsObj.datetime,  
  longMonthNames: ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'],
  currentDatetime: null,
  isToday: false,
  minutesInterval: null,
  init() {
    let now = new Date();
    const date = new Date(this.datetime);

    if(this.datetime === ""){ 
      return;
    }
    
    this.isToday = date.getDate() == now.getDate();

    let dateDiff = Math.max(now - date, 0)
    
    if(dateDiff < (60 * 60 * 1000)) {
      this.currentDatetime = this.minutesTemplate(Math.max(0,Math.floor((dateDiff/1000)/60)));

      if(this.minutesInterval === null) {
        this.minutesInterval = setInterval(() => {

          now = new Date();
          dateDiff = Math.max(now - date, 0)

          if(dateDiff > (60 * 60 * 1000)) {
            clearInterval(this.minutesInterval);
            this.setAndCalculateDayTemplate(date, Math.floor(dateDiff / 3600000));
          } else {
            this.currentDatetime = this.minutesTemplate(Math.max(0,Math.floor((dateDiff/1000)/60)));
          }

        }, (60 * 1000));
      }
      return;
    }

    const hoursDiff = Math.floor(dateDiff / 3600000);

    if(hoursDiff >= 1 && hoursDiff < 24) {
      this.setAndCalculateDayTemplate(date, hoursDiff);
      return;
    }
    
    /*if(hoursDiff < 365 * 24) {
      this.currentDatetime = `${date.getDate()}. ${this.shortMonthNames(date.getMonth())}`;
      return;
    }*/
    
    this.currentDatetime = `${date.getDate()}. ${this.shortMonthNames(date.getMonth())} ${date.getFullYear()}`;
  },
  destroy() {
    if(this.minutesInterval !== null) {
      clearInterval(this.minutesInterval);
    } 
  },
  setAndCalculateDayTemplate(date, hoursDiff) {
    const hh = date.getHours().toString().padStart(2, '0');
    const mm = date.getMinutes().toString().padStart(2, '0');
    this.currentDatetime = this.dayTemplate([`${hh}:${mm}`, hoursDiff + " " + (hoursDiff === 1 ? "time" : "timer")]);
  },
  shortMonthNames(index) {
    // Return a short version of the full month name. Ohh shit its på danish
    return (this.longMonthNames[index].length === 3) ? this.longMonthNames[index] : this.longMonthNames[index].substring(0, 3) + ".";
  },
  todayOrYesterday() {
    return this.isToday ? 'dag' : 'går';
  },
  minutesTemplate(value) {
    let mtString = "";
    if(this.formatTemplate === "latestNews") {
      if(value === 0) {
        return 'Lige nu';
      }
      mtString = `${value} min.`;
    } else if(this.formatTemplate === "breakingNews" || this.formatTemplate === "liveblogPost" || this.formatTemplate === "liveblogComment") {
      if(value === 0) {
        return 'Lige nu';
      }
      mtString = `${value} min. siden`;
    } else {
      mtString = `for ${value} min. siden`;
    }
    return mtString;
  },
  dayTemplate(values) {
    let dtString = "";
    if(this.formatTemplate === "latestNews") {
      dtString = `I ${this.todayOrYesterday()} ${values[0]}`;
    } else if(this.formatTemplate === "breakingNews") {
      dtString = `${values[1]} siden`;
    } else if(this.formatTemplate === "liveblogPost") {
      dtString = this.isToday ? `${values[0]}` : `I går kl. ${values[0]}`;
    } else if(this.formatTemplate === "liveblogComment") {
      dtString = this.isToday ? `kl. ${values[0]}` : `I går kl. ${values[0]}`;
    } else {
      dtString = `i ${this.todayOrYesterday()} kl. ${values[0]}`;
    }
    return dtString;
  }
}));